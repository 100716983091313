import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ManufacturerDto} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: "root"})
export class ManufacturerRest {
  http = inject(HttpClient);
  manufacturer: ManufacturerDto

  save(item: ManufacturerDto) {
    return this.http.post(`${environment.serverUrl}/api/manufacturer/save`, item)
  }

  findBySsoUserId(): Observable<ManufacturerDto> {
    return this.http.get(`${environment.serverUrl}/api/manufacturer/find-by-user`)
      .pipe(tap(e => {
        this.manufacturer = e
      }))
  }
}
